import React, { useState } from 'react'
import './faucet.css'
import CloverIcon from '../images/clover_icon.svg'
import BirdIcon from '../images/bird_icon.svg'
import ErrorIcon from '../images/error_icon.svg'
import SuccessIcon from '../images/success_icon.svg'
import WarningIcon from '../images/warning_icon.svg'
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

function Faucet() {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [statusIcon, setStatusIcon] = useState('')
    const [address, setAddress] = useState('0x0000000000000000000000000000000000000000')
    const [tweetUrl, setTweetUrl] = useState('')
    const [loading, setLoading] = useState(false);

    const hashTags = 'Clover,CLV,CloverNetwork'
    const textTemplate = (address: string) => {
        return `Requesting Clover preview net funds into ${address} !`
    }

    function composeTweetLink(address: string) {
        const text = textTemplate(address)
        const query = `text=${text}&&hashtags=${hashTags}&&related=CrustNewtowrk`
        return `https://twitter.com/intent/tweet?${query}`
    }

    const openTweetWindow = () => {
        const tweetUrl = composeTweetLink(address || '0x0000000000000000000000000000000000000000')
        if (!window.open(tweetUrl)) {
            setOpen(true)
            setMessage('Error opening browser window, please check your browser settings.')
            setStatusIcon(ErrorIcon)
        }
    }

    const tweetRegex = /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)(\?.*)?$/

    function extractTweetId(text: string | null): string {
        if (!text || text.length === 0 ) {
            return ''
        }
        const match = text.match(tweetRegex)
        if (match && match[3].length >0 ) {
            return match[3]
        }
        return ''
    }

    const getCLV = async () => {
        if (extractTweetId(tweetUrl)) {
            setLoading(true)
            const response = await fetch('https://faucet-iris-api.clover.finance/clover/api/faucet/request', {
                method: 'POST',
                body: JSON.stringify({tweetId: extractTweetId(tweetUrl)}),
                headers: {
                    'Accept': 'application/json,text/plain,*/*',
                    'Content-Type': 'application/json',
                },
            })

            if (response.ok) {
                setStatusIcon(SuccessIcon)
                setLoading(false)
                setMessage('3 CLV got!')
            } else {
                const res = await response.json()
                setLoading(false)
                setOpen(true)
                if (res.type === 'tweetIdAlreadyClaimed') {
                    setMessage("You've reached faucet limit. Please obtain after three days")
                    setStatusIcon(WarningIcon)
                } else {
                    setMessage('Get CLV Failed. Please try later')
                    setStatusIcon(ErrorIcon)
                }
            }
        }
    }

    return (
        <div className="first-page-wrapper">
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="alert-wrapper">
                    <img width={14} height={14} src={statusIcon} alt=""/>
                    {message}
                </div>
            </Snackbar>
            <div className="first-page-content-top flex-center">
                <img width={36} height={36} src={CloverIcon} alt=""/>
                <span>Clover Authenticated Faucet</span>
            </div>
            {loading && (
                <div className="loading-wrapper" >
                    <CircularProgress color="rgba(0, 0, 0, 0.8)" />
                </div>
            )}
            <div className="first-page-content-center flex-center">
                <input
                    type="text"
                    placeholder="Please paste the twitter link which contains your Clover address"
                    value={tweetUrl}
                    onChange={(e) => setTweetUrl(e.target.value)}
                />
                <button onClick={getCLV}>Give me CLV</button>
            </div>
            <div className="first-page-content-bottom">
                <h4>How to fund?</h4>
                <p>This faucet is running on the Clover preview network (Iris). To prevent malicious actors from exhausting all funds, requests are tied to Twitter social network accounts. Anyone having a
                    <span onClick={openTweetWindow}> Twitter </span>
                    account may request funds within the permitted limits.
                </p>
                <div className="flex-center">
                    <img width={30} height={26} src={BirdIcon} alt=""/>
                    <span>To request funds via Twitter, make a tweet with your Clover address pasted into the contents (surrounding text doesn't matter). Copy-paste the tweets URL into the above input box and get your CLV. Each account can get 3 CLV every three days.</span>
                </div>
            </div>

        </div>
    );
}

export default Faucet;
